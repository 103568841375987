import React from "react"
import { createRoot } from 'react-dom/client';
// Import Font Awesome Icons Set
import "@fortawesome/fontawesome-free/css/all.min.css"
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css"

import "scss/style.scss"
import "babel-polyfill"

import { PrimeReactProvider } from "primereact/api";
import App from "App"
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <App/>
    </PrimeReactProvider>
  </React.StrictMode>
)
