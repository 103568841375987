import { Buffer } from 'buffer';
const decodeURL = (url) => {
  if (url) {
    const decoded = Buffer.from(url.replace("/b/", ""), "base64").toString("ascii")
    if (decoded.startsWith(process.env.REACT_APP_API_BASE_URL)) {
      return decoded
    } else {
      throw new Error("Invalid url")
    }
  }
  return ""
}
export default decodeURL
