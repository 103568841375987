import React from "react"
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import { NotificationContainer } from "react-notifications"
import "react-notifications/lib/notifications.css"

import EntityType from "api/SirenEntityType"
import Entity from "components/Entity"
import PageLoader from "components/Layout/PageLoader"
import { getColorScheme, getChatSupport, getCustomCSSUrl, getFrontendTranslations, getIsLoggedIn } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"
import registry from "components/registry"

class Layout extends React.PureComponent {
  static propTypes = {
    header: EntityType,
    footer: EntityType,
    sidebar: EntityType,
    topNav: EntityType,
    layoutName: PropTypes.string,
    contentEntity: EntityType,
    error: PropTypes.object,
    history: PropTypes.object,
    currentUrl: PropTypes.string,
    prevUrl: PropTypes.string,
    loading: PropTypes.bool,
  }

  render() {
    const {
      error,
      currentUrl,
      prevUrl,
      loading,
      header,
      footer,
      sidebar,
      topNav,
      layoutName,
      contentEntity,
      history } = this.props

    const bodyClassName = contentEntity ? registry.getClassNameForClass(contentEntity.class) : ""

    if (error) {
      return <ErrorLayout error={error} history={history}/>
    }
    // core-ui require this class to be applied on the body element..
    document.body.classList[sidebar ? "add" : "remove"]("sidebar-fixed")
    document.body.classList[getIsLoggedIn() ? "add" : "remove"]("logged-in")

    const existingChatSupportScript = document.getElementById("chatSupport")
    if (getChatSupport() && !existingChatSupportScript) {
      const script = document.createElement("script")
      script.src = "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/655389ac1c8e7fb37ba6800b3622d4b3a49e94451b02f2a6c81028cd79a5267f.js"
      script.id = "chatSupport"
      document.body.appendChild(script)
    }

    const existingCSSfileLink = document.getElementById("cssFile")
    if (getCustomCSSUrl() && !existingCSSfileLink) {
      var x = document.createElement("LINK")
      x.setAttribute("rel", "stylesheet")
      x.setAttribute("type", "text/css")
      x.setAttribute("id", "cssFile")
      x.setAttribute("href", getCustomCSSUrl())
      document.head.appendChild(x)
    }

    return <React.Fragment>
      <NotificationContainer/>
      <PageLoader loading={loading}/>
      <Entity entity={header} sidebar={!!sidebar} topNav={topNav} currentUrl={currentUrl}/>
      <div className={`app-body ${bodyClassName}`}>
        <Entity entity={sidebar} currentUrl={currentUrl}/>
        <main className="main">
          <Container fluid className={`p-0 my-0 mx-auto ${sidebar || topNav ? "mt-4" : ""}`}>
            <Entity entity={contentEntity} currentUrl={currentUrl} prevUrl={prevUrl} layoutName={layoutName}/>
          </Container>
        </main>
      </div>
      <Entity entity={footer}/>
      <style jsx global>{`
        body {
          background-color: ${(contentEntity && contentEntity.properties.backgroundColor) || "white"};
        }
        .hero {
          height: 15em;
          color: white;
          background-color: ${getColorScheme().primaryColor};
          overflow-y: auto;
        }
        :root {
          --sc-color:  ${getColorScheme().primaryColor};
        }
      `}</style>
    </React.Fragment>
  }
}

const ErrorLayout = ({ error, history }) => (
  <main className="main d-flex flex-column justify-content-center align-items-center">
    <h2>{error.title} {error.status}</h2>
    <h1>{getFrontendTranslations() ? getFrontendTranslations().error.generic : translate("error.generic")}</h1>
    <div>{error.detail}</div>
    <h5><a href="#" onClick={history.goBack}>{getFrontendTranslations() ? getFrontendTranslations().error.goBack : translate("error.goBack")}</a></h5>
    <style jsx>{`
      .main { height: 100vh }
    `}</style>
  </main>
)
ErrorLayout.propTypes = {
  error: PropTypes.object,
  history: PropTypes.object,
}

export default Layout
