import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

import api, { ERROR_CANCELLED, ERROR_NOT_FOUND } from "api/api"
import get from "get"
import ErrorBoundary from "components/Error/ErrorBoundary"
import Context from "components/FormRenderer/formSubmissionContext"
import encodeURL from "urlHelpers/encodeURL"
import Layout from "components/Layout/Layout"
import PageMeta from "components/PageMeta"
import { isActionSuccess,
  getActionEntityItems,
  findAndReplace,
  getActionStatus,
  ACTION_STATUS } from "components/FormRenderer/actionEntity"
import { getLayoutFromApi, getContentEntity } from "components/Layout/layoutManager"
import decodeURL from "urlHelpers/decodeURL"

const iterateParents = (element, stopCondition) => {
  while (element) {
    const result = stopCondition(element)
    if (result !== undefined) {
      return result
    }
    // Using parentElement as the default skips comments
    // and other non-element nodes when traversing up the tree
    element = element.parentElement || element.parentNode
  }
}

const closestAnchor = (element) => iterateParents(element, (newElement) => newElement instanceof HTMLAnchorElement ? newElement : undefined)
const isInEditor = (element) => iterateParents(element, (newElement) => (newElement && newElement.className && newElement.className.indexOf("ql-editor") > -1) ? true : undefined)

const isHashBang = (anchor) => get(anchor.attributes, "href.value") === "#"
const isSameOrigin = (anchor) => !isHashBang(anchor) && anchor.href.indexOf(document.location.origin) === 0

const getBaseUrl = (url) => {
  let baseUrl = new URL(url)
  baseUrl.searchParams.delete("detailView")
  baseUrl.searchParams.delete("secondaryTable")
  return baseUrl.href
}

class GenericPage extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
  }

  state = {
    id: this.props.id,
    entity: undefined,
    error: undefined,
    refreshingCount: 0,
    prevUrl: undefined,
  }

  componentDidMount() {
    document.addEventListener("click", this.captureLinkClicks, true)
    this.refresh()
  }

  componentDidUpdate(prevProps) {
    const href = get(this.state.entity, "selfLink")
    const errorRecoveryCondition = (!this.state.id && (prevProps.id !== this.props.id)) // Recovering from error via back button
    if (prevProps.id !== this.props.id) {
      this.setState({ prevUrl: prevProps.id })
    }
    const linkCondition = (this.state.id && ((prevProps.id !== this.props.id) && (this.props.id !== this.state.id)))
    const formCondition = (href && href !== this.state.id)
    if (errorRecoveryCondition || linkCondition || formCondition) {
      const baseUrl = getBaseUrl(this.props.id)
      if (baseUrl !== this.state.id) {
        this.refresh()
      }
      const currentPropsId = new URL(this.props.id)
      const prevPropsId = new URL(prevProps.id)
      //whenever a secondary table is changed we need to check if the previous secondary table url and current secondary table url are the same.
      // if they not the same we make the api call
      if (currentPropsId.searchParams.has("secondaryTable")) {
        let currentSecondaryTableUrl = new URL(decodeURL(currentPropsId.searchParams.get("secondaryTable")))
        //api call should happen only for actions(pagination,search,filter etc) but not for row click
        //the secondary table url will contain "detailview" only only row click
        if (!currentSecondaryTableUrl.searchParams.has("detailView")) {
          currentSecondaryTableUrl = currentSecondaryTableUrl.href

          let prevSecondaryTableUrl
          if (prevPropsId.searchParams.has("secondaryTable")) {
            prevSecondaryTableUrl = new URL(decodeURL(prevPropsId.searchParams.get("secondaryTable")))
            prevSecondaryTableUrl.searchParams.delete("detailView")
            prevSecondaryTableUrl = prevSecondaryTableUrl.href
          }
          if (currentSecondaryTableUrl !== prevSecondaryTableUrl) {
            this.refresh(currentSecondaryTableUrl)
          }
        }
      }
    } else {
      // In case of a successful page transition with a new entity, reset
      // the scroll position
      prevProps.history.action === "PUSH" && window.scrollTo(0, 0)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.captureLinkClicks, true)
  }

  setLayout = (entity) => {
    getLayoutFromApi(entity).then((result) => {
      const { header, footer, sidebar, topNav, layoutName } = result
      this.setState({
        header,
        footer,
        sidebar,
        topNav,
        layoutName,
      })
    })
  }

  captureLinkClicks = (event) => {
    const { srcElement, target = srcElement } = event
    if (!(event.ctrlKey || event.shiftKey || event.metaKey || event.altKey)) {
      const anchor = closestAnchor(target)
      if (anchor && (anchor.type || isSameOrigin(anchor)) && !isInEditor(anchor)) {
        event.preventDefault()
        const href = anchor.href.replace(document.location.origin, "")
        // If a type is mentioned on the link don't refresh the page
        // This will initiate a download of the file
        if (anchor.type) {
          this.refresh(href, anchor.type)
        } else {
          this.props.history.push(href)
        }
        return false
      }
    }
    return true
  }

  setCancelRefresh = (cancel) => this.cancelRefresh = cancel

  setEntityState = (entity, id) => {
    // Empty responses can come from API calls in case a pdf/excel was downloaded
    if (!entity) {
      return
    }

    const { contentEntity } = getContentEntity(entity)
    this.setLayout(entity)

    this.setState({
      contentEntity,
      id,
    })
  }

  refresh(href = this.props.id, type) {
    this.cancelRefresh && this.cancelRefresh()
    this.setState(state => ({
      error: undefined,
      refreshingCount: state.refreshingCount + 1,
    }), () => {
      if (!this.state.contentEntity) {
        href = getBaseUrl(href)
      }
      api({ href, headers: (type && { "Accept": type }) || undefined, setCancel: this.setCancelRefresh })
      .then((result) => {
        if (result.fileDownloaded) {
          this.setState((state) => ({ refreshingCount: state.refreshingCount - 1 }))
          return
        }
        if (result === ERROR_NOT_FOUND) {
          throw { status: 404, detail: "Page not found" } //i18n
        }

        const { entity } = result
        // entity can be undefined in case of pdf or excel download.

        if (this.state.contentEntity && new URL(this.props.id).searchParams.has("secondaryTable")) {

          const newSecondaryTableEntity = result.entity
          const newSecondaryTableEntitySelfLink = result.entity.selfLink
          const existingContentEntity = { ...this.state.contentEntity }

          const latestContentEntityEntities = existingContentEntity.entities.map(eachPrimaryTableEntity => {
            const hasMatchedPrimaryTableEntity = eachPrimaryTableEntity.selfLink && newSecondaryTableEntitySelfLink.includes(eachPrimaryTableEntity.selfLink)
            if (hasMatchedPrimaryTableEntity) {
              const newNestedEntities = eachPrimaryTableEntity.entities.map((nestedEntity) => {
                const hasMatchedNestedEntity = nestedEntity.key && newSecondaryTableEntitySelfLink.includes(nestedEntity.key.split("?")[0])
                if (hasMatchedNestedEntity) {
                  return newSecondaryTableEntity
                }
                return nestedEntity
              })
              return { ...eachPrimaryTableEntity, entities: newNestedEntities }
            }
            return eachPrimaryTableEntity
          })

          const contentEntity = { ...this.state.contentEntity, entities: latestContentEntityEntities }
          this.setState({ contentEntity: contentEntity })
        } else {
          this.setEntityState(entity, (entity && entity.selfLink) || this.props.id)

          if (new URL(this.props.id).searchParams.has("secondaryTable")) {

            const newContentEntity = { ...this.state.contentEntity }
            const browserSecondaryTableUrl = new URL(decodeURL(new URL(this.props.id).searchParams.get("secondaryTable"))).href

            newContentEntity?.entities?.map(eachPrimaryTableEntity => {
              const hasMatchedPrimaryTableEntity = eachPrimaryTableEntity.selfLink && browserSecondaryTableUrl.includes(eachPrimaryTableEntity.selfLink)
              if (hasMatchedPrimaryTableEntity) {
                eachPrimaryTableEntity.entities.map((nestedEntity) => {
                  const hasMatchedNestedEntity = nestedEntity.key && browserSecondaryTableUrl.includes(nestedEntity.key.split("?")[0])
                  if (hasMatchedNestedEntity) {
                    if (browserSecondaryTableUrl !== nestedEntity.key) {
                      this.refresh(browserSecondaryTableUrl)
                    }
                  }
                })
              }
            })
          }

        }
        this.setState((state) => ({ refreshingCount: state.refreshingCount - 1 }))
      }).catch(error => {
        console.error(error)
        const isCancelled = error.type === ERROR_CANCELLED
        !isCancelled && this.setState({
          error,
          id: undefined,
          header: undefined,
          footer: undefined,
          sidebar: undefined,
          topNav: undefined,
          contentEntity: undefined })
        this.setState((state) => ({ refreshingCount: state.refreshingCount - 1 }))
      })
    })
  }

  onEntityResolve = ({ entity, url }) => {
    /* Based on comment from DanAbramov https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates
      we can safely rely on batch batchedUpdates until React 17. */
    ReactDOM.unstable_batchedUpdates(() => {
      url && this.props.history.push(encodeURL(url))
      this.setEntityState(entity, url)
    })
  }

  onActionResolve = (entity) => {
    if (isActionSuccess(entity) && getActionStatus(entity) === ACTION_STATUS.COMPLETED) {
      const items = getActionEntityItems(entity)
      const { contentEntity } = this.state
      items.forEach(item => {
        if (item.selfLink === contentEntity.selfLink) {
          this.setState({ contentEntity: item })
        } else {
          // FIXME: useless code? (typo in entities)
          const entites = findAndReplace(contentEntity.entities, [item])
          this.setState({ ...contentEntity, entites: entites })
        }
      })
    } else {
      console.log(`${ACTION_STATUS.POTENTIAL} and ${ACTION_STATUS.FAILED} don't need any handling right now.`)
    }
  }

  render() {
    const {
      error,
      id,
      prevUrl,
      refreshingCount,
      header,
      footer,
      sidebar,
      topNav,
      layoutName,
      contentEntity } = this.state

    if (!header && !footer && !sidebar && !topNav && !contentEntity && !error) {
      return null
    }
    return <Context.Provider value={{ onEntityResolve: this.onEntityResolve, onActionResolve: this.onActionResolve }}>
      <PageMeta entity={contentEntity}/>
      <Layout
        header={header}
        footer={footer}
        sidebar={sidebar}
        topNav={topNav}
        layoutName={layoutName}
        contentEntity={contentEntity}
        error={error}
        currentUrl={id}
        prevUrl={prevUrl}
        loading={refreshingCount > 0}
        history={this.props.history}/>
    </Context.Provider>
  }
}

const SafeGenericPage = ({ id, ...props }) => (
  <ErrorBoundary id={id}>
    <GenericPage id={id} {...props}/>
  </ErrorBoundary>
)
SafeGenericPage.propTypes = {
  id: PropTypes.string,
}

export default SafeGenericPage
