import { UiLayoutClass, HeaderClass, FooterClass, SideNavClass, TopNavClass, ColorSchemeClass, FrontendTranslationsClass, UiConfigurationClass } from "components/registry"
import { findByClass, findByRelation } from "api/utils"
import { setItem, getItem } from "storage"
import api from "api/api"

const COLOR_SCHEME = "colorScheme"
const CHAT_SUPPORT = "chatSupport"
const CUSTOM_CSS_URL = "customCSSUrl"
const Frontend_Translations = "frontendTranslations"
const DEFAULT_COLOR_SCHEME = {
  primaryColor: "#0376ba",
}
const DEFAULT_FAVICON = "/favicon.ico"
const ENV_TITLE = "envTitle"
const LOGGED_IN = "loggedIn"

const setColorScheme = (color) => setItem(COLOR_SCHEME, color)
export const setFrontendTranslations = (translations) => setItem(Frontend_Translations, translations)
const setChatSupport = (chatSupportEnabled) => setItem(CHAT_SUPPORT, chatSupportEnabled)
const setCustomCSSUrl = (customCSSUrl) => setItem(CUSTOM_CSS_URL, customCSSUrl)
const setEnvTitle = (title) => setItem(ENV_TITLE, title)
const setIsLoggedIn = (loggedIn) => setItem(LOGGED_IN, loggedIn)

export const getColorScheme = () => getItem(COLOR_SCHEME) || DEFAULT_COLOR_SCHEME
export const getFrontendTranslations = () => getItem(Frontend_Translations)
export const getChatSupport = () => getItem(CHAT_SUPPORT) || false
export const getCustomCSSUrl = () => getItem(CUSTOM_CSS_URL) || undefined
export const getEnvTitle = () => getItem(ENV_TITLE) || ""
export const getIsLoggedIn = () => getItem(LOGGED_IN) || false

const setFavicon = (imageUrl) => {
  imageUrl ||= DEFAULT_FAVICON
  let link = document.querySelector("link[rel~='icon']")
  if (!link) {
    link = document.createElement("link")
    link.rel = "icon"
    document.head.appendChild(link)
  }
  link.href = imageUrl
}

export const getLayoutFromApi = (entity = {}) => {
  const layout = findByRelation(entity.links, "layout")
  if (!layout) {
    return new Promise(() => {
      return { header: undefined, footer: undefined, sidebar: undefined, topNav: undefined }
    })
  }
  else {
    return layout && api({ href: layout.href }).then((result) => {
      const uiLayout = result.entity
      const locale = result.headers['content-language']
      if (locale) {
        document.querySelector('html').setAttribute('lang', locale)
      }
      const layoutName = uiLayout && findByRelation(uiLayout.links, "layoutName").href
      const uiConfigEntity = uiLayout && findByClass(uiLayout.entities, UiConfigurationClass)
      setFavicon(uiConfigEntity && uiConfigEntity.properties.favicon_url)
      uiConfigEntity && setEnvTitle(uiConfigEntity.title)
      const colorScheme = uiConfigEntity && findByClass(uiConfigEntity.entities, ColorSchemeClass)
      const frontendTranslationsEntity = uiConfigEntity && findByClass(uiConfigEntity.entities, FrontendTranslationsClass)
      const chatSupport = uiConfigEntity && uiConfigEntity.properties.evopark_userlike_chat_support_enabled
      const customCSSUrl = uiConfigEntity && uiConfigEntity.properties.custom_css_url
      chatSupport !== undefined && setChatSupport(chatSupport)
      customCSSUrl !== undefined && setCustomCSSUrl(customCSSUrl)
      setColorScheme(colorScheme ? colorScheme.properties : DEFAULT_COLOR_SCHEME)
      frontendTranslationsEntity && setFrontendTranslations(frontendTranslationsEntity.properties)
      const header = uiLayout && findByClass(uiLayout.entities, HeaderClass)
      const footer = uiLayout && findByClass(uiLayout.entities, FooterClass)
      const sidebar = uiLayout && findByClass(uiLayout.entities, SideNavClass)
      const topNav = uiLayout && findByClass(uiLayout.entities, TopNavClass)
      const isLoggedIn = header && (header.actions && header.actions.find(a => a.name === "logout") !== undefined) ? true : false
      setIsLoggedIn(isLoggedIn)
      return { header, footer, sidebar, topNav, layoutName }
    })
  }

}

export const getContentEntity = (entity = {}) => {
  const layoutEntitiesClass = [UiLayoutClass, HeaderClass, FooterClass, SideNavClass, TopNavClass]
  const contentEntities = entity.entities.filter(e => layoutEntitiesClass.every(entityClass => e.class.indexOf(entityClass) === -1))
  return { contentEntity: { ...entity, entities: contentEntities } }
}
