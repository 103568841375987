import { lazy } from "react"

export const UiLayoutClass = "https://schema.evopark.com/UiLayout"
export const HeaderClass = "https://schema.evopark.com/Header"
export const FooterClass = "https://schema.evopark.com/Footer"
export const SideNavClass = "https://schema.evopark.com/SideNav"
export const TopNavClass = "https://schema.evopark.com/TopNav"
export const ColorSchemeClass = "https://schema.evopark.com/ColorScheme"
export const FrontendTranslationsClass = "https://schema.evopark.com/FrontendTranslations"
export const UiConfigurationClass = "https://schema.evopark.com/UiConfiguration"
export const LandingPageClass = "https://schema.evopark.com/LandingPage"
export const HeroClass = "https://schema.evopark.com/Hero"
export const FacilityFinderClass = "https://schema.evopark.com/FacilityFinder"
export const TextBlockClass = "https://schema.evopark.com/TextBlock"
export const LoginClass = "https://schema.evopark.com/Login"
export const ResetPasswordClass = "https://schema.evopark.com/PasswordReset"
export const UpdatePasswordClass = "https://schema.evopark.com/PasswordUpdate"
export const PasswordResetSuccessClass = "https://schema.evopark.com/PasswordResetSuccess"
export const SignupClass = "https://schema.evopark.com/Signup"
export const OrganizationClass = "http://schema.org/Organization"
export const ParkingFacilityPageClass = "https://schema.evopark.com/ParkingFacilityPage"
export const ConfigurationClass = "https://schema.evopark.com/Configuration"
export const OperatorDashboardClass = "https://schema.evopark.com/OperatorDashboard"
export const ItemListClass = "http://schema.org/ItemList"
export const MultiItemListClass = "http://schema.org/MultiItemList"
export const CustomerClass = "https://schema.evopark.com/Customer"
export const CustomerDashboardClass = "https://schema.evopark.com/CustomerDashboard"
export const GenericEntityClass = "https://schema.evopark.com/GenericEntity"
export const PostalAddressClass = "http://schema.org/PostalAddress"
export const SepaMandateClass = "https://schema.evopark.com/SepaMandate"
export const CardPaymentMethodClass = "https://schema.evopark.com/CardPaymentMethod"
export const ParkingFacilityContactClass = "https://schema.evopark.com/ParkingFacilityContact"
export const OpeningTimesClass = "https://schema.evopark.com/OpeningTimes"
export const LocationFeatureSpecificationClass = "http://schema.org/LocationFeatureSpecification"
export const BookingChangeClass = "https://schema.evopark.com/BookingChange"
export const BookingChangeListClass = "https://schema.evopark.com/BookingChangeList"
export const BookingRequestClass = "https://schema.evopark.com/BookingRequest"
export const BookingRequestListClass = "https://schema.evopark.com/BookingRequestList"
export const CustomerSelfServiceBookingRequestClass = "https://schema.evopark.com/CustomerSelfService/BookingRequest"
export const BillingPeriodClass = "https://schema.evopark.com/BillingPeriod"
export const ParkingFacilityClass = "http://schema.org/ParkingFacility"
export const ParkingPermissionClass = "https://schema.evopark.com/ParkingPermission"
export const EmployeeClass = "https://schema.evopark.com/Employee"
export const EmployeeGroupClass = "https://schema.evopark.com/EmployeeGroup"
export const ContractBookingClass = "https://schema.evopark.com/ContractBooking"
export const ContractBookingListClass = "https://schema.evopark.com/ContractBookingList"
export const BillingSegmentListClass = "https://schema.evopark.com/BillingSegmentList"
export const InvoiceClass = "http://schema.org/Invoice"
export const InvoiceListClass = "https://schema.evopark.com/InvoiceList"
export const StandingInvoiceListClass = "https://schema.evopark.com/StandingInvoiceList"
export const CustomerAndMediaContractMappingClass = "https://schema.evopark.com/CustomerAndMediaContractMapping"
export const CustomerAndMediaContractMappingListClass = "https://schema.evopark.com/CustomerAndMediaContractMappingList"
export const ProductOfferClass = "https://schema.evopark.com/ProductOffer"
export const ParkingProductListClass = "https://schema.evopark.com/ParkingProductList"
export const ParkingProductConfigurationListClass = "https://schema.evopark.com/ParkingProductConfigurationList"
export const permissionCollectionListClass = "https://schema.evopark.com/PermissionCollectionList"
export const EasyPromotionClass = "https://schema.evopark.com/EasyPromotion"
export const ContractClass = "https://schema.evopark.com/Contract"
export const IdentificationMediumClass = "https://schema.evopark.com/IdentificationMedium"
export const IdentificationComponentClass = "https://schema.evopark.com/IdentificationComponent"
export const IdentificationMediaListClass = "https://schema.evopark.com/IdentificationMediaList"
export const PermissionCapacityClass = "https://schema.evopark.com/PermissionCapacity"
export const NewBookingRequestPageClass = "https://schema.evopark.com/NewBookingRequestPage"
export const WaitlistClass = "https://schema.evopark.com/Waitlist"
export const WaitlistRequestListClass = "https://schema.evopark.com/WaitlistRequestList"
export const ImportedCustomerOnboardingPageClass = "https://schema.evopark.com/ImportedCustomerOnboarding"
export const EventLogClass = "https://schema.evopark.com/EventLog"
export const EmailLogClass = "https://schema.evopark.com/EmailLog"
export const TemplateClass = "https://schema.evopark.com/Template"
export const GarageSystemClass = "https://schema.evopark.com/GarageSystem"
export const ParkingProductUsageProfileAssignmentClass = "https://schema.evopark.com/ParkingProductUsageProfileAssignment"
export const GarageSystemErrorClass = "https://schema.evopark.com/GarageSystemError"
export const customFieldClass = "http://schema.org/CustomField"
export const ParkingTransactionListClass = "https://schema.evopark.com/ParkingTransactionList"
export const PaymentTransactionListClass = "https://schema.evopark.com/PaymentTransactionList"
export const PaymentTransactionClass = "https://schema.evopark.com/PaymentTransaction"
export const ParkingTransactionClass = "http://schema.evopark.com/ParkingTransaction"
export const SummaryClass = "http://schema.evopark.com/PaymentsSummaryLine"
export const BillingSegmentClass = "http://schema.evopark.com/BillingSegment"
export const PaymentClass = "http://schema.evopark.com/Payment"

const components = {
  Header: { class: HeaderClass, component: lazy(() => import("components/Layout/Header/HeaderEntity")) },
  Footer: { class: FooterClass, component: lazy(() => import("components/Layout/Footer/Footer")) },
  SideNav: { class: SideNavClass, component: lazy(() => import("components/Layout/Sidebar/Sidebar")) },
  TopNav: { class: TopNavClass, component: lazy(() => import("components/Layout/TopNav")) },

  LandingPage: { class: LandingPageClass, component: lazy(() => import("components/pages/public/LandingPage/LandingPage")), className: "landing-page" },
  Hero: { class: HeroClass, component: lazy(() => import("components/pages/public/LandingPage/Hero/Hero")) },
  FacilityFinder: { class: FacilityFinderClass, component: lazy(() => import("components/pages/public/LandingPage/FacilityFinder")) },
  TextBlock: { class: TextBlockClass, component: lazy(() => import("components/pages/public/LandingPage/TextBlock")) },

  Login: { class: LoginClass, component: lazy(() => import("components/pages/public/Account/Login")) },
  ResetPassword: { class: ResetPasswordClass, component: lazy(() => import("components/pages/public/Account/UpdateOrResetPassword")) },
  UpdatePassword: { class: UpdatePasswordClass, component: lazy(() => import("components/pages/public/Account/UpdateOrResetPassword")), className: "customer reset-password" },
  PasswordResetSuccess: { class: PasswordResetSuccessClass, component: lazy(() => import("components/pages/public/Account/PasswordResetSuccess")) },
  Signup: { class: SignupClass, component: lazy(() => import("components/pages/public/Account/Signup/Signup")), className: "signup" },
  Organization: { class: OrganizationClass, component: lazy(() => import("components/pages/admin/Organization/Organization")) },
  EasyPromotion: { class: EasyPromotionClass, component: lazy(() => import("components/FormRenderer/FormPage")) },
  ParkingFacilityPage: { class: ParkingFacilityPageClass, component: lazy(() => import("components/pages/public/ParkingFacilityPage/ParkingFacilityPage")), className: "parking-facility" },
  Configuration: { class: ConfigurationClass, component: lazy(() => import("components/pages/admin/AdminPage/Configuration")) },
  OperatorDashboard: { class: OperatorDashboardClass, component: lazy(() => import("components/pages/admin/AdminPage/OperatorDashboard")) },

  GenericEntity: { class: GenericEntityClass, component: lazy(() => import("components/GenericEntity")) },

  PostalAddress: { class: PostalAddressClass, component: lazy(() => import("components/pages/public/ParkingFacilityPage/Address")) },
  ParkingFacilityContact: { class: ParkingFacilityContactClass, component: lazy(() => import("components/pages/public/ParkingFacilityPage/Contact")) },
  OpeningTimes: { class: OpeningTimesClass, component: lazy(() => import("components/pages/public/ParkingFacilityPage/OpeningTimes")) },
  LocationFeatureSpecification: { class: LocationFeatureSpecificationClass, component: lazy(() => import("components/pages/public/ParkingFacilityPage/ParkingFacilityFeature")) },

  InvoiceList: { class: InvoiceListClass, component: lazy(() => import("components/pages/customer/CustomerDashboardList")) },
  CustomerAndMediaContractMappingList: {
    class: CustomerAndMediaContractMappingListClass,
    component: lazy(() => import("components/pages/customer/CustomerDashboardList")),
  },
  // BookingChange: { class: BookingChangeClass, component: lazy(() => import("components/pages/admin/BookingRequest/BookingChange")) },
  // BookingChangeList: { class: BookingChangeListClass, component: lazy(() => import("components/pages/admin/BookingRequest/BookingChangeList")) },
  BookingRequestList: { class: BookingRequestListClass, component: lazy(() => import("components/pages/customer/CustomerDashboardList")) },
  BookingRequest: { class: BookingRequestClass, component: lazy(() => import("components/pages/admin/BookingRequest/BookingRequest")) },
  CustomerBooking: { class: CustomerSelfServiceBookingRequestClass, component: lazy(() => import("components/pages/customer/CustomerBooking")) },
  WaitlistRequestList: { class: WaitlistRequestListClass, component: lazy(() => import("components/pages/customer/CustomerDashboardList")) },
  IdentificationMediaList: { class: IdentificationMediaListClass, component: lazy(() => import("components/pages/customer/CustomerDashboardList")) },

  Contract: { class: ContractClass, component: lazy(() => import("components/pages/customer/Contract")) },

  ItemList: { class: ItemListClass, component: lazy(() => import("components/TableRenderer/TableRenderer")), className: "item-list" },
  MultiItemListClass: { class: MultiItemListClass, component: lazy(() => import("components/TableRenderer/MultiTableRenderer")) },

  CustomerDashboard: { class: CustomerDashboardClass, component: lazy(() => import("components/pages/customer/CustomerDashboard")), className: "customer-dashboard" },
  Customer: { class: CustomerClass,
    default: lazy(() => import("components/pages/customer/CustomerAccount")),
    table: lazy(() => import("components/pages/customer/CustomerAccountTableView")),
    className: "customer",
  },
  ImportedCustomerOnboarding: { class: ImportedCustomerOnboardingPageClass, component: lazy(() => import("components/pages/customer/CustomerOnboardingPage")), className: "signup" },
  IdentificationMedium: { class: IdentificationMediumClass, component: lazy(() => import("components/pages/admin/IdentificationMedium/IdentificationMedium")) },
  BillingPeriod: { class: BillingPeriodClass, component: lazy(() => import("components/pages/admin/BillingPeriod/BillingPeriod")) },

  ParkingFacility: { class: ParkingFacilityClass, component: lazy(() => import("components/pages/admin/ParkingFacility/ParkingFacility")) },
  ParkingPermission: { class: ParkingPermissionClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["name", "description"],
    },
  },
  Employee: { class: EmployeeClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["email", "roles", "status", "lockedAt"],
    },
  },
  EmployeeGroup: { class: EmployeeGroupClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["membersCount", "createdBy"],
    },
  },
  ContractBooking: { class: ContractBookingClass, component: lazy(() => import("components/pages/admin/ContractBooking/ContractBooking")) },
  Invoice: { class: InvoiceClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["billingPeriod", "confirmationNumber", "netTotal+currency", "vatRate", "vatTotal+currency"],
      entities: [
        { class: "http://schema.org/MonetaryAmount", properties: ["value+currency"] },
      ],
    },
  },
  CustomerAndMediaContractMapping: {
    class: CustomerAndMediaContractMappingClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["contract_id", "userfield3"],
    },
  },
  ProductOffer: { class: ProductOfferClass, component: lazy(() => import("components/pages/admin/ProductOffer/ProductOffer")) },
  NewBookingRequestPage: { class: NewBookingRequestPageClass, component: lazy(() => import("components/pages/public/Account/Signup/NewBookingRequestPage")) },
  Waitlist: { class: WaitlistClass, component: lazy(() => import("components/pages/public/Account/Signup/Waitlist")) },
  EventLog: {
    class: EventLogClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["category", "name", "occurredAt", "editor", "user", "prettyMessage"],
    },
  },
  EmailLog: {
    class: EmailLogClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["recipient_customer_number", "sent_at", "recipient_type", "recipient_name", "recipient_email", "subject", "body_content"],
    },
  },
  Template: { class: TemplateClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["assignment", "title", "subject", "body", "state", "defaultTemplate"],
    },
  },
  ParkingProductUsageProfileAssignment: { class: ParkingProductUsageProfileAssignmentClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["productName", "usageProfileName"],
    },
  },
  GarageSystem: { class: GarageSystemClass, component: lazy(() => import("components/pages/admin/GarageSystem/GarageSystem")) },
  GarageSystemErrorClass: { class: GarageSystemErrorClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["message", "body", "code", "created_at", "updated_at", "backtrace", "source_type", "source_id", "count"],
    },
  },
  customField: { class: customFieldClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["customizableType", "description", "id", "kind", "required"],
    },
  },
  parkingTransaction: { class: ParkingTransactionClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["amount_decimal", "area_name", "duration", "entered_at", "exited_at", "status"],
    },
  },
  PaymentTransaction: { class: PaymentTransactionClass,
    component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: ["order_number", "amount", "customer_number", "customer_name", "charge_type", "opened_on", "updated_at",
      ],
    },
  },
  BillingSegment: { class: BillingSegmentClass, component: lazy(() => import("components/pages/admin/BillingSegment/BillingSegment")),
  },
  Payment: { class: PaymentClass, component: lazy(() => import("components/TableRenderer/TableDetailView")),
    config: {
      properties: [
        "order_number", "facility_id", "location_id", "transaction_id", "customer_number", "customer_name",
        "charge_type", "opened_on", "created_at", "updated_at", "status", "amount", "business_transaction_id",
        "payment_method_number", "error_message", "external_identifier", "details",
      ],
    },
  },
}

const componentKeys = Object.keys(components)

const findComponentKey = (classes) => (
  componentKeys.find(key => classes.find(klass => klass === components[key].class)) || "GenericEntity"
)

const registry = {
  getComponentForClass: (classes = [], context = "default") => {
    const componentKey = findComponentKey(classes)
    return components[componentKey].component || components[componentKey][context] || null
  },
  getConfigForClass: (classes = []) => {
    const componentKey = findComponentKey(classes)
    return components[componentKey].config || {}
  },
  getClassNameForClass: (classes = []) => {
    const componentKey = findComponentKey(classes)
    return components[componentKey].className || ""
  },
}

export default registry
