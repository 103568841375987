import axios from "axios"

import { findByRelation } from "api/utils"
import get from "get"
import { getItem, setItem, removeItem } from "storage"
import normalizeEntity from "api/entity"
import encodeURL from "urlHelpers/encodeURL"
import setSearchParam from "urlHelpers/setSearchParam"
import history from "appHistory"
import { getFrontendTranslations, setFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"
import { getActionStatus,
  ACTION_STATUS } from "components/FormRenderer/actionEntity"


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const API_OPERATOR_URL = document.location.hostname
export const API_URL = `${API_BASE_URL}/${API_OPERATOR_URL}`
export const ACCESS_TOKEN_KEY = "accessToken"
export const LANGUAGE_KEY = "lang"
export const ERROR_CANCELLED = "https://schema.evopark.com/errors/cancelled"
export const ERROR_VALIDATION = "https://schema.evopark.com/errors/validation"
export const ERROR_NOT_FOUND = "NOT_FOUND"
const CONTENT_TYPES = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"]

const isInternal = url => url.indexOf(API_BASE_URL) === 0 || url.indexOf("http://localhost") === 0

const api = ({
  href, method = "GET", data, headers, setCancel, downloadFile = false,
}) => (
  request(method, href, data, headers, setCancel, downloadFile)
  .then(response => {
    if (CONTENT_TYPES.indexOf(response.headers["content-type"].split(";")[0]) !== -1) {
      try {
        const file = new Blob([response.data], { type: response.headers["content-type"] })
        setTimeout(() => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file)
            return
          }
          const url = window.URL.createObjectURL(file)
          const link = document.createElement("a")
          link.href = url
          const fileNameMatch = /filename=['"]([\w.]+)['"]/i.exec(response.headers["content-disposition"])
          if (Array.isArray(fileNameMatch)) {
            link.download = fileNameMatch[1]
          }
          // link.click() does not work in Firefox
          // see https://stackoverflow.com/questions/32225904/programmatical-click-on-a-tag-not-working-in-firefox/48367757#48367757
          link.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }))
          setTimeout(() => {
            window.URL.revokeObjectURL(url)
          })
        })
      } catch (e) {
        console.error(e)
      }
      return { fileDownloaded: true }
    } else if (response.headers["content-type"] === "text/html; charset=UTF-8") {
      // text/html is returned when typing a wrong url something like: stussy.easycontract-dev.de/b/randomStuff
      // it will get the html from the index.html, so we need to display a 404
      return ERROR_NOT_FOUND
    }

    if (response.headers["content-type"] == "application/json; charset=utf-8") {
      return response.data
    }
    const requestUrl = process.env.NODE_ENV === "development" ? response.config.url : undefined
    response.data = normalizeEntity(response.data, requestUrl)
    findAndPersistAccessToken(response.data.entities)
    !getItem(LANGUAGE_KEY) && response.headers["content-language"] && setItem(LANGUAGE_KEY, response.headers["content-language"])
    return { entity: response.data, headers: response.headers }
  })
)
export default api

const request = (method, url, data, headers = {}, setCancel, downloadFile = false) => {
  let params = {}
  if (data && method.toLowerCase() == "get") {
    params = data
    data = null
  } else if (data && headers["Content-Type"] === "application/x-www-form-urlencoded") {
    data = Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join("&")
  }

  const cancelToken = typeof setCancel === "function" ? axios.CancelToken.source() : undefined
  cancelToken && setCancel((message = "Cancelled") => cancelToken.cancel(message))

  const responseType = (downloadFile || (CONTENT_TYPES.indexOf(headers["Accept"]) !== -1)) ? "arraybuffer" : undefined

  return axios({
    url,
    method,
    data,
    params,
    cancelToken: cancelToken && cancelToken.token,
    responseType: responseType,
    headers: {
      "Accept": "application/vnd.evopark-siren+json, application/vnd.siren+json, application/problem+json, application/json",
      ...(getItem(LANGUAGE_KEY) && { "X-Selected-Locale": getItem(LANGUAGE_KEY) }),
      ...(isInternal(url) && { "Authorization": getAuthorizationHeader() }),
      ...headers,
    },
    validateStatus: (status) => (status >= 200 && status <= 304) || status === 401,
    timeout: 120000,
  }).catch(error => {
    if (axios.isCancel(error)) {
      return Promise.reject({
        type: ERROR_CANCELLED,
        instance: url,
        title: error.message,
      })
    }

    if (isTimeout(error)) {
      const type = "https://schema.evopark.com/errors/timeout"
      const detail = error.message
      return Promise.reject({
        type,
        instance: url,
        title: getFrontendTranslations() ? getFrontendTranslations().error.timeout : translate("error.timeout"),
        detail,
      })
    }

    if (!error.response) {
      const type = `https://schema.evopark.com/errors/${navigator.onLine ? "generic" : "offline"}`
      const detail = navigator.onLine ? error.message : getFrontendTranslations() ? getFrontendTranslations().error.offline : translate("error.offline")

      return Promise.reject({
        type,
        instance: url,
        title: error.message,
        detail,
      })
    }

    const contentType = error.response.headers["content-type"]
    // TODO: Server is returning improper content for application/problem+json
    // So when it pretends that, we also check the payload. The payload check can
    // later be removed on both `if` conditions.
    if (contentType === "application/problem+json" && error.response.data.type) {
      return Promise.reject(error.response.data)
    } else if (contentType === "application/json" || !error.response.data.type) {
      if (error.response.data.properties && error.response.data.properties.errors) {
        const detail = error.response.data.properties.errors.reduce((result, { name, message }) => {
          result[name] = result[name] ? result[name] + `, ${message}` : message
          return result
        }, {})
        return Promise.reject({
          type: ERROR_VALIDATION,
          instance: url,
          status: error.response.status,
          invalidParams: detail,
          title: (error.response.data.properties && error.response.data.properties.message),
        })
      } else if (contentType.indexOf("application/vnd.evopark-siren+json") > -1 && getActionStatus(error.response.data) === ACTION_STATUS.FAILED) {
        return Promise.reject(error.response.data)
      }
    }

    return Promise.reject({
      type: "https://schema.evopark.com/errors/generic",
      instance: url,
      status: error.response.status,
      title: (error.response.data.properties && error.response.data.properties.message) || error.response.statusText || statusToTitle[error.response.status],
    })
  })
}

// TODO: Remove this when the server sends proper statusText
const statusToTitle = {
  401: "Anmeldedaten sind ungültig",
}

const isTimeout = (error) => get(error.response, "status") === 408 || error.code === "ECONNABORTED"

const findAndPersistAccessToken = (entities = []) => {
  const accessToken = entities.find(entity => entity.rel && entity.rel.indexOf("accessToken") > -1)
  accessToken && persistAccessToken(accessToken)
}

// redirect to other customer_host - required if parkingFacilities belongs to other grouped (!) organization
const redirectCustomerHostInterceptor = response => {
  // axios automatically follows redirects.
  // we detect redirect afterward by comparing request / response
  const requestUrl = response.config.url
  const responseUrl = response.request.responseURL
  if (requestUrl && responseUrl && requestUrl !== responseUrl) {
    const redirectUrl = new URL(responseUrl)
    const newHost = redirectUrl.pathname.split("/")[1]
    if (newHost !== document.location.hostname) {
      const newUrl = new URL(window.location)
      newUrl.host = newHost
      newUrl.pathname = encodeURL(responseUrl)
      window.location.replace(newUrl.toString())
      // prevent error from beeing shown:
      // https://github.com/axios/axios/issues/583#issuecomment-504317347
      return new Promise(() => {})
    }
  }
  return response
}

axios.interceptors.response.use(redirectCustomerHostInterceptor)

const responseErrorInterceptor = error => {
  const expiredToken = expiredAccessToken(error)
  if (expiredToken) {
    return refreshToken(expiredToken).then((accessToken) => {
      error.config.headers.authorization = getAuthorizationHeader(accessToken)
      return axios.request(error.config)
    })
  }
  return Promise.reject(error)
}

axios.interceptors.response.use(null, responseErrorInterceptor)

const expiredAccessToken = error => {
  try {
    const { grant_type } = JSON.parse(error.config.data)
    return grant_type === "password" && error.config && get(error, "config.headers.authorization") && get(error, "response.status") === 401 && fetchAccessToken()
  } catch (_error) {
    return false
  }
}

const refreshToken = (accessToken) => {
  const { refresh_token, links } = accessToken
  const { method, href } = findByRelation(links, "refresh")

  return request(method, href, { grant_type: "refresh_token", refresh_token })
  .then(response => {
    response.data
      ? persistAccessToken(response.data)
      : removeAccessToken(response.headers)
    return response.data
  })
}

const getAuthorizationHeader = (accessToken = fetchAccessToken()) => {
  if (accessToken) {
    const { token, token_type } = accessToken
    return `${token_type} ${token}`
  }
}

const fetchAccessToken = () => getItem(ACCESS_TOKEN_KEY)
const persistAccessToken = (accessToken) => setItem(ACCESS_TOKEN_KEY, { ...accessToken.properties, links: accessToken.links })

// append a `logout` to the query param to force a refresh of the GenericPage when logging out from the landing page
// Otherwise nothing re-render so it's confusing. As the logout redirect is always landing page itself.
const removeAccessToken = (headers) => {
  removeItem(ACCESS_TOKEN_KEY)
  if (headers && headers.location) {
    const url = setSearchParam({ url: `${document.location.origin}${encodeURL(headers.location)}`, name: "logout", value: 1 })
    history.push(url.pathname + url.search)
  } else {
    document.location.reload()
  }
}

export const logout = ({ href, method }) =>
  api({ href, method })
  .then(({ headers }) => removeAccessToken(headers))
  .catch(() => removeAccessToken())

export const setLocale = ({ href, method }) => {
  return api({ href, method })
  .then(payload => {
    setFrontendTranslations(payload.data)
    setItem(LANGUAGE_KEY, payload.locale)
    window.location.reload()
  })
  .catch((e) => alert(`failed to set locale: ${JSON.stringify(e)}`))
}
